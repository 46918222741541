import Router from 'next/router'
import { useEffect } from 'react'

const Home: React.FC<React.PropsWithChildren> = () => {
  useEffect(() => {
    Router.push('/swap?chainId=33139')
  }, [])
  return <></>
}

export default Home
